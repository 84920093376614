var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("h1", [_vm._v("退会の手続き")]),
          _vm.message
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.message))
              ])
            : _vm._e(),
          _vm.errMessage
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("システムエラーが発生しました。"),
                _c(
                  "div",
                  [
                    _vm._v(
                      "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/inquiry_form/input" } },
                      [_vm._v("こちら")]
                    ),
                    _vm._v("よりをお問い合わせください。")
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("p", [_vm._v("以下のアカウントの退会手続きを行います。")]),
          _c("div", { staticClass: "form-group" }, [
            _c("div", [_vm._v("メールアドレス")]),
            _c("div", [_vm._v(_vm._s(_vm.mail))])
          ]),
          _vm.isPay ? _c("div", [_vm._m(0), _vm._m(1)]) : _vm._e(),
          _c("div", [_vm._v(_vm._s(_vm.message))]),
          !_vm.isPay
            ? _c(
                "form",
                {
                  staticClass: "needs-validation",
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.exec($event)
                    }
                  }
                },
                [
                  _c("p", { staticClass: "mt-4" }, [
                    _vm._v("差し支えなければ退会理由を教えてください。")
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "form-check form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.Q1,
                            expression: "Q1"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "unsubscribe1",
                          type: "checkbox",
                          name: "unsubscribe"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.Q1)
                            ? _vm._i(_vm.Q1, null) > -1
                            : _vm.Q1
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.Q1,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.Q1 = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.Q1 = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.Q1 = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "unsubscribe1" }
                        },
                        [_vm._v("使いたい機能が少ない")]
                      )
                    ]),
                    _c("div", { staticClass: "form-check form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.Q2,
                            expression: "Q2"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "unsubscribe2",
                          type: "checkbox",
                          name: "unsubscribe"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.Q2)
                            ? _vm._i(_vm.Q2, null) > -1
                            : _vm.Q2
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.Q2,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.Q2 = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.Q2 = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.Q2 = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "unsubscribe2" }
                        },
                        [_vm._v("使い方がわかりずらい")]
                      )
                    ]),
                    _c("div", { staticClass: "form-check form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.Q3,
                            expression: "Q3"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "unsubscribe3",
                          type: "checkbox",
                          name: "unsubscribe"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.Q3)
                            ? _vm._i(_vm.Q3, null) > -1
                            : _vm.Q3
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.Q3,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.Q3 = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.Q3 = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.Q3 = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "unsubscribe3" }
                        },
                        [_vm._v("利用料が高い")]
                      )
                    ]),
                    _c("div", { staticClass: "form-check form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.Q4,
                            expression: "Q4"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "unsubscribe4",
                          type: "checkbox",
                          name: "unsubscribe"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.Q4)
                            ? _vm._i(_vm.Q4, null) > -1
                            : _vm.Q4
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.Q4,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.Q4 = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.Q4 = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.Q4 = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "unsubscribe4" }
                        },
                        [_vm._v("外岩クライミングする機会がない")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "unsubscribe5" } }, [
                      _vm._v("その他")
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Q5,
                          expression: "Q5"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "unsubscribe5",
                        type: "text",
                        name: "unsubscribe",
                        value: "5"
                      },
                      domProps: { value: _vm.Q5 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.Q5 = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("input", {
                    staticClass: "btn btn-primary",
                    attrs: {
                      type: "submit",
                      value: "退会手続きを実行する",
                      disabled: _vm.isDisabled
                    }
                  })
                ]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-danger" }, [
      _c("div", { staticClass: "mt-5" }, [
        _vm._v("現在、有料プランをご利用中です。")
      ]),
      _c("div", { staticClass: "mt-2" }, [
        _vm._v(
          "お支払い情報を削除してから、本画面にて退会の手続きを実施してください。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "delPay" }, [
      _c("div", { staticClass: "mt-4" }, [_vm._v("お支払い情報の削除方法：")]),
      _c("div", [
        _vm._v(
          "[アカウント設定] -≻ [支払情報] から「無料プランに変更」ボタンよりお手続きください。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }