<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center 
        .col-lg-6
          h1 退会の手続き
          .alert.alert-danger(v-if="message") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          p 以下のアカウントの退会手続きを行います。
          .form-group 
            div メールアドレス
            div {{ mail }}
          div(v-if="isPay")
            .text-danger
              div.mt-5 現在、有料プランをご利用中です。
              div.mt-2 お支払い情報を削除してから、本画面にて退会の手続きを実施してください。
            .delPay
              div.mt-4 お支払い情報の削除方法：
              div [アカウント設定] -≻ [支払情報] から「無料プランに変更」ボタンよりお手続きください。
          div {{ message }}
          form.needs-validation(@submit.prevent="exec" novalidate v-if="!isPay")
            p.mt-4 差し支えなければ退会理由を教えてください。
            .form-group
              .form-check.form-group
                input#unsubscribe1.form-check-input(type="checkbox" v-model="Q1" name="unsubscribe")
                label.form-check-label(for="unsubscribe1") 使いたい機能が少ない
              .form-check.form-group
                input#unsubscribe2.form-check-input(type="checkbox" v-model="Q2" name="unsubscribe")
                label.form-check-label(for="unsubscribe2") 使い方がわかりずらい
              .form-check.form-group
                input#unsubscribe3.form-check-input(type="checkbox" v-model="Q3" name="unsubscribe")
                label.form-check-label(for="unsubscribe3") 利用料が高い
              .form-check.form-group
                input#unsubscribe4.form-check-input(type="checkbox" v-model="Q4" name="unsubscribe")
                label.form-check-label(for="unsubscribe4") 外岩クライミングする機会がない
            .form-group
              label(for="unsubscribe5") その他
              textarea#unsubscribe5.form-control(type="text" v-model="Q5" name="unsubscribe" value="5")
            input.btn.btn-primary(type="submit" value="退会手続きを実行する" v-bind:disabled="isDisabled")
</template>

<script>
import axios from 'axios'

import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      name: '',
      mail: '',
      message: '',
      Q1: false,
      Q2: false,
      Q3: false,
      Q4: false,
      Q5: '',
      isDisabled: false,
      isPay: false,
      errMessage: false,
    }
  },
  mounted() {
    const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user'
    axios.get(jsonUrl, {
      headers: {
        'Authorization': this.$user.idToken
      }
    })
    .then(res => {
      this.isPay = (res.data.payment != null && res.data.payment != 'free')
    })
    .catch(err => {
      console.log(err)
    })
  },
  async created() {
    const poolData = {
      UserPoolId : process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID
    }
    this.userPool = new CognitoUserPool(poolData)

    //サインインしていない場合
    this.$user.cognito.getUserAttributes((err, result) => {
      if (err) {
        this.$router.push({ name: 'login', query: { url:this.$route.path }}) //ページ移動
        return
      }
      //表示名の取得
      for (let i = 0; i < result.length; i++) {
        if (result[i].getName() == 'email') {
          this.mail = result[i].getValue()
        }
      }
    })
  },
  methods: {
    //ダイヤログ表示内容
    asyncDialog() {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxConfirm('退会手続きを行うと今まで作成した記録がすべて削除されます', {
          title: '退会手続きを進めてよろしいですか？',
          okVariant: 'danger',
          okTitle: '退会する',
          cancelTitle: '中止する',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    asyncPayDialog() {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk('先に支払情報の削除手続きを実施してください。', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          footerClass: 'p-2',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },
    //退会処理
    async exec(e) {

      this.errMessage = false

      //ダイヤログの表示
      if(this.isPay == true) {
        await this.asyncPayDialog()
        return
      }else {
        let isYes = await this.asyncDialog()
        if (!isYes) return
      }

      this.Q5 = this.Q5.trim()

      if (this.$user.cognito != null) {
        this.$user.cognito.deleteUser((err, result) =>{
          // 処理結果Error
          if (err) {
            if(err.code == 'TooManyRequestsException') {
              this.message = 'しばらく時間をおいてから再度お試しください。'
            }else {
              this.errMessage = true
            }
            this.isDisabled = false
            return
          }
          // 処理結果OK
          //ユーザー削除処理
          const url = process.env.VUE_APP_API_GATEWAY + '/api-user'
          const params = {
            data: {
              q1: this.Q1,
              q2: this.Q2,
              q3: this.Q3,
              q4: this.Q4,
              other: this.Q5,
            },
            headers: {
              'Authorization': this.$user.idToken
            }
          }
          //内容送信
          axios.delete(url, params)
            .then(res => {
              localStorage.removeItem("mail")
              this.$router.push('/unsubscribe/complete')
              this.isDisabled = false
            })
            .catch(err => {
              console.log(err)
            })
          
        })
      }else {
        // 処理結果Error
        this.message = 'ログインしてください'
        this.isDisabled = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.delPay
  font-size 0.8rem
</style>